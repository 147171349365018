import React, { useState } from 'react';
import Jumbotron from 'components/layouts/Jumbotron';
import QuizQuestion, { Question } from './QuizQuestion';
import TraitBars from 'components/TraitBars/TraitBars';
import TraitCarousel from 'components/TraitCarousel/TraitCarousel';
import 'pages/PersonalityQuiz/quiz.css';

interface QuestionsData {
  [key: string]: Question[];
}

interface MBTIScore {
  [key: string]: number;
  O: number;
  C: number;
  E: number;
  A: number;
  N: number;
}

const questionsData: QuestionsData = {
  O: [
    {
      id: 'O1',
      questionText: 'I see beauty in things that others might not notice.',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
    {
      id: 'O2',
      questionText: 'I like to get lost in thought.',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
    {
      id: 'O3',
      questionText: 'How important is achieving personal goals to you?',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
    {
      id: 'O4',
      questionText: 'Enjoy spending time by myself',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
    {
      id: 'O5',
      questionText: 'Can handle a lot of information',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
  ],
  C: [
    {
      id: 'C1',
      questionText: 'I spent more than an hour thinking about what to wear.',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
    {
      id: 'C2',
      questionText: 'I Accomplish my work on time',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
    {
      id: 'C3',
      questionText: 'I like to break rules',
      score: 0,
      scoreEmoji: '🤔',
      charge: -1,
    },
    {
      id: 'C4',
      questionText: 'I Am always prepared',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
    {
      id: 'C5',
      questionText: 'I Am not embarrassed easily',
      score: 0,
      scoreEmoji: '🤔',
      charge: -1,
    },
  ],
  E: [
    {
      id: 'E1',
      questionText: 'I am quiet around strangers',
      score: 0,
      scoreEmoji: '🤔',
      charge: -1,
    },
    {
      id: 'E2',
      questionText: 'Never at a loss for words',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
    {
      id: 'E3',
      questionText: 'Bottle up my feelings',
      score: 0,
      scoreEmoji: '🤔',
      charge: -1,
    },
    {
      id: 'E4',
      questionText: 'I Warm up quickly to others',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
    {
      id: 'E5',
      questionText: 'I Can talk others into doing things',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
  ],
  A: [
    {
      id: 'A1',
      questionText: 'I Have a sharp tongue',
      score: 0,
      scoreEmoji: '🤔',
      charge: -1,
    },
    {
      id: 'A2',
      questionText: 'Am annoyed by others\' mistakes',
      score: 0,
      scoreEmoji: '🤔',
      charge: -1,
    },
    {
      id: 'A3',
      questionText: 'I am hard to get to know.',
      score: 0,
      scoreEmoji: '🤔',
      charge: -1,
    },
    {
      id: 'A4',
      questionText: 'I am concerned about others',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
    {
      id: 'A5',
      questionText: 'Put people under pressure',
      score: 0,
      scoreEmoji: '🤔',
      charge: -1,
    },
  ],
  N: [
    {
      id: 'N1',
      questionText: 'I Don\'t worry about things that have already happened.',
      score: 0,
      scoreEmoji: '🤔',
      charge: -1,
    },
    {
      id: 'N2',
      questionText: 'I am very pleased with myself.',
      score: 0,
      scoreEmoji: '🤔',
      charge: -1,
    },
    {
      id: 'N3',
      questionText: 'I Keep my emotions under control',
      score: 0,
      scoreEmoji: '🤔',
      charge: -1,
    },
    {
      id: 'N4',
      questionText: 'I Can be stirred up easily.',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
    {
      id: 'N5',
      questionText: 'I Have frequent mood swings',
      score: 0,
      scoreEmoji: '🤔',
      charge: 1,
    },
  ],
};

const mbtiProfiles: { [key: string]: MBTIScore } = {
  INFJ: { O: 4, C: 3.8, E: 1.8, A: 4.5, N: 3.1 },
  INFP: { O: 4, C: 1.1, E: 2.1, A: 3.2, N: 3.1 },
  INTJ: { O: 4, C: 4, E: 1.5, A: 1.5, N: 3 },
  INTP: { O: 3.8, C: 2, E: 2.1, A: 1, N: 3 },
  ISFJ: { O: 1.3, C: 3.2, E: 1.3, A: 4.5, N: 3.5 },
  ISFP: { O: 2.3, C: 1.1, E: 1.5, A: 4, N: 4.5 },
  ISTJ: { O: 1.2, C: 4, E: 1.2, A: 1.5, N: 3.2 },
  ISTP: { O: 2.2, C: 1.5, E: 2, A: 1, N: 3.5 },
  ENFJ: { O: 3.8, C: 4.5, E: 4, A: 5, N: 2.5 },
  ENFP: { O: 4.8, C: 2, E: 4.8, A: 4.5, N: 2.8 },
  ENTJ: { O: 3.7, C: 4.9, E: 4.5, A: 2, N: 1.5 },
  ENTP: { O: 4.7, C: 2.8, E: 4.7, A: 1.5, N: 2.5 },
  ESFJ: { O: 2.2, C: 4, E: 3.9, A: 5, N: 3 },
  ESFP: { O: 2, C: 2, E: 4.5, A: 4.5, N: 3 },
  ESTJ: { O: 2, C: 4.9, E: 3.9, A: 2.8, N: 2.9 },
  ESTP: { O: 2, C: 2.2, E: 4.2, A: 1.5, N: 2.9 },
};

interface ResultsState {
  [key: string]: number;
  O_count: number;
  C_count: number;
  E_count: number;
  A_count: number;
  N_count: number;
}

const convertToProfile = (results: ResultsState): MBTIScore => {
  return {
    O: results.O_count ? results.O / results.O_count : 0,
    C: results.C_count ? results.C / results.C_count : 0,
    E: results.E_count ? results.E / results.E_count : 0,
    A: results.A_count ? results.A / results.A_count : 0,
    N: results.N_count ? results.N / results.N_count : 0,
  };
};

const calculateMBTIType = (profile: MBTIScore) => {
  let minDistance = Infinity;
  let bestMatch = '';

  console.log('Profile to match:', profile);

  Object.entries(mbtiProfiles).forEach(([type, refProfile]) => {
    const distance = Object.keys(refProfile).reduce((acc, trait) => {
      const traitDistance = Math.abs((profile[trait] || 0) - (refProfile[trait] || 0));
      return acc + traitDistance;
    }, 0);

    if (distance < minDistance) {
      minDistance = distance;
      bestMatch = type;
    }
  });

  console.log('Best MBTI Match:', bestMatch);
  return bestMatch;
};

const PersonalityQuiz: React.FC<{ title: string }> = () => {
  const [currentTrait, setCurrentTrait] = useState('O');
  const [questions, setQuestions] = useState(questionsData[currentTrait]);
  const [results, setResults] = useState<ResultsState>({
    O: 0,
    C: 0,
    E: 0,
    A: 0,
    N: 0,
    O_count: 0,
    C_count: 0,
    E_count: 0,
    A_count: 0,
    N_count: 0,
  });
  const [quizFinished, setQuizFinished] = useState(false);
  const [mbtiType, setMbtiType] = useState('');

  const handleScoreChange = (index: number, newScore: number) => {
    const updatedQuestions = [...questions];
    const question = updatedQuestions[index];
    const traitKey = question.id.charAt(0);
    const oldScore = question.score;

    // Update the question data directly from slider input
    question.score = newScore;
    question.scoreEmoji = getEmoji(newScore);
    updatedQuestions[index] = question;

    // Set the updated questions array
    setQuestions(updatedQuestions);

    // Update the results for traits, considering the charge
    setResults((prevResults) => {
      const oldAdjustedScore = oldScore * question.charge;
      const newAdjustedScore = newScore * question.charge;
      const scoreDiff = newAdjustedScore - oldAdjustedScore;

      const updatedResults = { ...prevResults };
      updatedResults[traitKey] = (updatedResults[traitKey] || 0) + scoreDiff;

      // Update counts only the first time the score changes from 0 to a non-zero value
      if (oldScore === 0 && newScore !== 0) {
        updatedResults[`${traitKey}_count`] = (updatedResults[`${traitKey}_count`] || 0) + 1;
      } else if (oldScore !== 0 && newScore === 0) {
        updatedResults[`${traitKey}_count`] = (updatedResults[`${traitKey}_count`] || 1) - 1;
      }
      console.log(`Trait Key: ${traitKey}, Old Adjusted Score: ${oldAdjustedScore}, New Adjusted Score: ${newAdjustedScore}`);
      console.log('Updated Results:', updatedResults);

      return updatedResults;
    });
  };

  const getEmoji = (score: number): string => {
    if (score < -3) return '🙃';
    if (score < 0) return '🙁'; // TODO: Confirm Emoji list
    if (score === 0) return '🤔';
    if (score <= 3) return '😏';
    return '🤩';
  };

  const handleNextTrait = () => {
    const traits = ['O', 'C', 'E', 'A', 'N'];
    const nextIndex = (traits.indexOf(currentTrait) + 1) % traits.length;
    setCurrentTrait(traits[nextIndex]);
    setQuestions(questionsData[traits[nextIndex]]);
  };

  const onFinishQuiz = () => {
    const profile = convertToProfile(results);
    const calculatedMbtiType = calculateMBTIType(profile);
    setMbtiType(calculatedMbtiType);
    setQuizFinished(true);
  };

  if (quizFinished) {
    const profile = convertToProfile(results);
    console.log("Rendering TraitCarousel with profile:", profile); // Add this log
    return (
      <Jumbotron>
        <div className="container text-center">
          <h1 className="quiz-jumbotron-title">Your MBTI Type is: {mbtiType}</h1>
          <TraitCarousel profile={profile} />
          <TraitBars results={profile} />
        </div>
      </Jumbotron>
    );
  }

  return (
    <Jumbotron columView>
      <>
        <div className="container text-center">
          <h1 className="quiz-jumbotron-title">Personality Quiz</h1>
        </div>
        <div className="quiz-container jumbotron-element-fade-in">
          {questions.map((question, index) => (
            <QuizQuestion key={question.id} question={question} onScoreChange={(newScore) => handleScoreChange(index, newScore)} />
          ))}
          <div className="quiz-button-container">
            <button onClick={currentTrait === 'N' ? onFinishQuiz : handleNextTrait} className="next-button">
              {currentTrait === 'N' ? 'Finish' : 'Next'}
            </button>
          </div>
        </div>
      </>
    </Jumbotron>
  );
};

export default PersonalityQuiz;
