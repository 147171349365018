import React from 'react';

interface TraitInsightsProps {
  trait: 'Openness' | 'Conscientiousness' | 'Extraversion' | 'Agreeableness' | 'Neuroticism';
  score: number;
}

const insights = {
  Openness: {
    high: [
      "Your open-mindedness allows you to embrace and appreciate diverse perspectives, fostering mutual understanding with your partner.",
      "Your curiosity and love for novelty can bring excitement and adventure into the relationship, ensuring there’s rarely a dull moment.",
      "Your imaginative nature can help in visualizing a vibrant future together and planning unique dates or activities."
    ],
    medium: [
      "Your ability to be both open-minded and grounded provides stability while still allowing room for growth.",
      "You appreciate a mix of deep conversations and light-hearted banter, catering to varied emotional needs.",
      "You can adapt to both conventional and unconventional situations, offering flexibility in the relationship."
    ],
    low: [
      "Your preference for familiar routines offers a sense of stability and consistency in the relationship.",
      "Your cautious approach to new experiences ensures that decisions are well-thought-out and risks are minimized.",
      "Your resilience to passing trends ensures that the relationship is built on lasting values."
    ]
  },
  Conscientiousness: {
    high: [
      "Your dependability and reliability can serve as a strong foundation in the relationship, offering stability and trust.",
      "Your goal-oriented nature ensures that you work towards a prosperous future with your partner, with clear plans and aspirations in place.",
      "Your strong sense of duty and responsibility can make your partner feel secure, knowing that commitments are taken seriously."
    ],
    medium: [
      "Your ability to switch between being detail-oriented and seeing the bigger picture helps in navigating relationship challenges effectively.",
      "You can maintain a steady routine but are open to change, ensuring the relationship remains fresh and evolving.",
      "You appreciate the importance of commitments but also understand the need for flexibility, fostering understanding in the relationship."
    ],
    low: [
      "You bring a sense of adventure and adaptability, allowing the relationship to evolve organically.",
      "You value living in the moment, ensuring that the relationship feels fresh and present-focused.",
      "Your easy-going demeanor often diffuses tensions, fostering a relaxed environment in the relationship."
    ]
  },
  Extraversion: {
    high: [
      "Your social energy can infuse the relationship with vibrancy and enthusiasm, leading to a dynamic bond with your partner.",
      "Your comfort in expressing feelings and thoughts can foster open communication, ensuring transparency in the relationship.",
      "Your proactive approach in addressing issues and taking initiative can lead to swift conflict resolution and progress in the relationship."
    ],
    medium: [
      "Your moderate energy level ensures you’re present in the moment, whether it’s an animated event or a quiet evening.",
      "Your ability to switch between being the life of the party and a reflective partner ensures the relationship remains dynamic and multifaceted.",
      "You can adapt to your partner’s energy, whether it’s a quiet evening at home or an outing with friends."
    ],
    low: [
      "You value personal space and understand its importance, fostering mutual respect for boundaries in the relationship.",
      "You offer a calm and reflective presence, creating a comforting space for your partner.",
      "Your introspective nature leads to deep, meaningful conversations and connections in your relationships."
    ]
  },
  Agreeableness: {
    high: [
      "Your decisiveness often means issues get addressed head-on, leading to efficient problem-solving and conflict resolution.",
      "Your proactive nature can lead to taking meaningful actions that progress and nurture the relationship.",
      "Your partner might appreciate your confidence and assurance, finding comfort in your ability to take charge when needed."
    ],
    medium: [
      "You can navigate disagreements with both confidence and empathy, ensuring that discussions are constructive.",
      "Your ability to compromise ensures that decisions are collaborative, with both partners feeling valued.",
      "Your balanced approach allows you to voice your needs while also being receptive to your partner’s opinions."
    ],
    low: [
      "You have a low agreeableness score, indicating a more competitive and self-focused approach.",
      "A low agreeableness score reflects a direct and sometimes critical personality.",
      "Your low agreeableness score suggests you are straightforward and prioritize your own needs."
    ]
  },
  Neuroticism: {
    high: [
      "You have the potential to transform your vulnerabilities into strengths by fostering self-awareness and personal growth.",
      "Your capacity to feel deeply can lead to powerful moments of passion and intensity, enriching the bond.",
      "You often possess a keen sense of intuition, picking up on subtle emotional cues that might go unnoticed by others."
    ],
    medium: [
      "Your ability to experience a range of emotions adds depth to the relationship, fostering mutual understanding and growth.",
      "Your emotional depth paired with stability ensures that you can both comfort and be comforted in the relationship.",
      "You can connect deeply with your partner during emotional moments while also offering a calming presence when needed."
    ],
    low: [
      "Your calm demeanor helps diffuse potential tensions and maintains a peaceful relationship atmosphere.",
      "Your emotional stability serves as a rock in the relationship, offering consistent support and understanding.",
      "You often see challenges as solvable problems rather than insurmountable obstacles, fostering optimism in the relationship."
    ]
  }
};

const traitColors: { [key in TraitInsightsProps['trait']]: string } = {
  Openness: 'orange',
  Conscientiousness: 'teal',
  Extraversion: 'green',
  Agreeableness: 'red',
  Neuroticism: 'blue'
};

const TraitInsights: React.FC<TraitInsightsProps> = ({ trait, score }) => {
  const getRandomInsight = (insightsArray: string[]) => {
    return insightsArray[Math.floor(Math.random() * insightsArray.length)];
  };

  let insight = "";

  if (score >= 4) {
    insight = getRandomInsight(insights[trait].high);
  } else if (score >= 2) {
    insight = getRandomInsight(insights[trait].medium);
  } else {
    insight = getRandomInsight(insights[trait].low);
  }

  return (
    <div className="trait-insight" style={{ backgroundColor: traitColors[trait] }}>
      <h3>{trait}</h3>
      <p>{insight}</p>
    </div>
  );
};

export default TraitInsights;