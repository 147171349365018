export const isValidName = (name: string): boolean => {
    // Allow letters, hyphens, and apostrophes
    const nameRegex = /^[a-zA-Z]+([-']?[a-zA-Z]+)*$/
    return nameRegex.test(name)
}

export const isValidEmail = (email: string): boolean => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

export const isValidAge = (age: string): boolean => {
    // Simple age validation (assuming age is a positive integer)
    const ageRegex = /^\d+$/
    return ageRegex.test(age)
}
