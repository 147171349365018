import React, { useState, useMemo, useRef, ChangeEvent, FormEvent } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, AuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import * as route from 'constants/routes';
import { isValidName, isValidEmail, isValidAge } from 'utils/formValidation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebookF, faApple } from '@fortawesome/free-brands-svg-icons';
import 'components/UserRegistrationForm/UserRegistrationForm.css';
import { PURLE_WHITE_ICON } from 'constants/assets';

const validateSignUpForm = (formData: FormData): FieldErrors => {
    const errors: FieldErrors = {};

    if (!isValidName(formData.firstName)) {
        errors.firstName = {
            error: true,
            errorMessage: 'First Name is required and must be a valid name'
        };
    }

    if (!isValidName(formData.lastName)) {
        errors.lastName = {
            error: true,
            errorMessage: 'Last Name is required and must be a valid name'
        };
    }

    if (!isValidEmail(formData.email)) {
        errors.email = {
            error: true,
            errorMessage: 'Email is required and must be a valid email address'
        };
    }

    if (!formData.socialLocation.trim()) {
        errors.socialLocation = {
            error: true,
            errorMessage: 'Social location is required'
        };
    }

    if (!isValidAge(formData.age)) {
        errors.age = {
            error: true,
            errorMessage: 'Age is required and must be a valid age'
        };
    }

    if (!formData.password.trim()) {
        errors.password = {
            error: true,
            errorMessage: 'Password is required'
        };
    } else if (!formData.passwordConfirm.trim()) {
        errors.passwordConfirm = {
            error: true,
            errorMessage: 'Confirm password is required'
        };
    } else if (formData.password !== formData.passwordConfirm) {
        errors.passwordConfirm = {
            error: true,
            errorMessage: 'Passwords must match'
        };
    }

    return errors;
};

interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    socialLocation: string;
    age: string;
    day: number | null;
    month: number | null;
    year: number | null;
    password: string;
    passwordConfirm: string;
    subscribeNewsletter: boolean;
}

interface FieldErrors {
    [fieldName: string]: {
        error: boolean;
        errorMessage: string;
    };
}

interface UserData {
    firstName: string;
    lastName: string;
    email: string;
    socialLocation: string;
    age: string;
    subscribeNewsletter: boolean;
}

const UserRegistrationForm: React.FC = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState<FormData>({
        firstName: '',
        lastName: '',
        email: '',
        socialLocation: '',
        age: '',
        day: null,
        month: null,
        year: null,
        password: '',
        passwordConfirm: '',
        subscribeNewsletter: false
    });
    const [formErrors, setFormErrors] = useState<FieldErrors>({});
    const formRef = useRef<HTMLFormElement | null>(null);
    const [signUpInProgress, setSignUpProgress] = useState<boolean>(false);

    const currentYear = new Date().getFullYear();
    const yearOptions = useMemo(() => {
        return Array.from({ length: 100 }, (_, index) => currentYear - index);
    }, [currentYear]);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { id, type, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value
        }));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        console.log('Form data submitted:', formData);

        const isSigningUp = false;

        if (isSigningUp) return;

        setSignUpProgress(true);

        const validationErrors = validateSignUpForm(formData);

        if (Object.keys(validationErrors).length > 0) {
            console.error('Validation errors:', validationErrors);
            setFormErrors(validationErrors);
            setSignUpProgress(false);
            return;
        }

        const {
            firstName,
            lastName,
            email,
            socialLocation,
            age,
            password,
            passwordConfirm,
            subscribeNewsletter
        } = formData;

        console.log({
            firstName,
            lastName,
            email,
            socialLocation,
            age,
            password,
            passwordConfirm,
            subscribeNewsletter
        });

        const userData: UserData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            socialLocation: socialLocation,
            age: age,
            subscribeNewsletter: subscribeNewsletter
        };

        const firestore = getFirestore();

        addDoc(collection(firestore, 'pre-registration'), userData)
            .then(() => {
                alert('User data added to pre-registration!');

                if (formRef.current) {
                    formRef.current.reset();
                }

                navigate(route.SIGN_UP_THANK_YOU);
            })
            .catch((error) => {
                console.error('Error storing user data: ', error);
            })
            .finally(() => {
                setSignUpProgress(false);
            });
    };

    const handleSocialLogin = async (provider: 'google' | 'facebook' | 'apple') => {
        const auth = getAuth();
        let socialProvider: AuthProvider | null = null;
        if (provider === 'google') {
            socialProvider = new GoogleAuthProvider();
        } else if (provider === 'facebook') {
            socialProvider = new FacebookAuthProvider();
        } else if (provider === 'apple') {
            socialProvider = new OAuthProvider('apple.com');
        }

        if (!socialProvider) {
            console.error('Unsupported provider');
            return;
        }

        try {
            const result = await signInWithPopup(auth, socialProvider);
            const user = result.user;
            const userData: UserData = {
                firstName: user.displayName?.split(' ')[0] || '',
                lastName: user.displayName?.split(' ')[1] || '',
                email: user.email || '',
                socialLocation: '',
                age: '',
                subscribeNewsletter: false
            };

            const firestore = getFirestore();

            await addDoc(collection(firestore, 'pre-registration'), userData);
            navigate(route.SIGN_UP_THANK_YOU);
        } catch (error) {
            console.error('Error during social login: ', error);
        }
    };

    return (
        <div id="login-box">
            <div className="left">
                <div className="logo-wrapper">
                    <img
                        src={PURLE_WHITE_ICON}
                        alt="Generic placeholder - TODO"
                        className="form-logo"
                    />
                </div>
                <h1>Sign up</h1>

                <form ref={formRef} onSubmit={handleSubmit}>
                    {formErrors?.firstName && (
                        <p className="input-error-text">{formErrors.firstName.errorMessage}</p>
                    )}
                    <input
                        type="text"
                        id="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleChange}
                    />

                    {formErrors?.lastName && (
                        <p className="input-error-text">{formErrors.lastName.errorMessage}</p>
                    )}
                    <input
                        type="text"
                        id="lastName"
                        placeholder="Second Name"
                        value={formData.lastName}
                        onChange={handleChange}
                    />

                    {formErrors?.email && (
                        <p className="input-error-text">{formErrors.email.errorMessage}</p>
                    )}
                    <input
                        type="text"
                        id="email"
                        placeholder="E-mail"
                        value={formData.email}
                        onChange={handleChange}
                    />

                    {formErrors?.socialLocation && (
                        <p className="input-error-text">{formErrors.socialLocation.errorMessage}</p>
                    )}
                    <input
                        type="text"
                        id="socialLocation"
                        placeholder="Social Location - e.g London"
                        value={formData.socialLocation}
                        onChange={handleChange}
                    />

                    {formErrors?.age && (
                        <p className="input-error-text">{formErrors.age.errorMessage}</p>
                    )}
                    <input
                        type="text"
                        id="age"
                        placeholder="Age"
                        value={formData.age}
                        onChange={handleChange}
                    />

                    <div className="d-flex justify-content-between">
                        <select
                            id="day"
                            value={formData.day !== null ? formData.day : ''}
                            onChange={handleChange}>
                            <option value="" disabled>
                                Day
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                            <option value={22}>22</option>
                            <option value={23}>23</option>
                            <option value={24}>24</option>
                            <option value={25}>25</option>
                            <option value={26}>26</option>
                            <option value={27}>27</option>
                            <option value={28}>28</option>
                            <option value={29}>29</option>
                            <option value={30}>30</option>
                            <option value={31}>31</option>
                        </select>

                        <select
                            id="month"
                            value={formData.month !== null ? formData.month : ''}
                            onChange={handleChange}>
                            <option value="" disabled>
                                Month
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                        </select>

                        <select
                            id="year"
                            value={formData.year !== null ? formData.year : ''}
                            onChange={handleChange}>
                            <option value="" disabled>
                                Year
                            </option>
                            {yearOptions.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                    </div>

                    {formErrors?.password && (
                        <p className="input-error-text">{formErrors.password.errorMessage}</p>
                    )}
                    <input
                        type="password"
                        id="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                    />

                    {formErrors?.passwordConfirm && (
                        <p className="input-error-text">
                            {formErrors.passwordConfirm.errorMessage}
                        </p>
                    )}
                    <input
                        type="password"
                        id="passwordConfirm"
                        placeholder="Retype password"
                        value={formData.passwordConfirm}
                        onChange={handleChange}
                    />

                    <div>
                        <input
                            type="checkbox"
                            id="subscribeNewsletter"
                            checked={formData.subscribeNewsletter}
                            onChange={handleChange}
                        />{' '}
                        <label htmlFor="subscribeNewsletter" className="newsletter-label">Subscribe to Newsletter</label>
                    </div>

                    <button type="submit" className="primary-button submit" disabled={signUpInProgress}>
                        Submit
                    </button>
                </form>
            </div>

            <div className="or">
                <p>OR</p>
            </div>

            <div className="right">
                <span className="loginwith">
                    Sign up with
                    <br />
                    social network
                </span>

                <button className="social-signin facebook" onClick={() => handleSocialLogin('facebook')}>
                    <FontAwesomeIcon icon={faFacebookF} />
                </button>
                <button className="social-signin google" onClick={() => handleSocialLogin('google')}>
                    <FontAwesomeIcon icon={faGoogle} />
                </button>
                <button className="social-signin apple" onClick={() => handleSocialLogin('apple')}>
                    <FontAwesomeIcon icon={faApple} />
                </button>
            </div>
        </div>
    );
};

export default UserRegistrationForm;
