import React from 'react'
import Jumbotron from 'components/layouts/Jumbotron'

const NotFound: React.FC = () => {
    // TODO - check if this ID can be updated to the correct
    return (
        <Jumbotron>
            <div className="container text-center">
                <h1>Page Not Found</h1>
                <p>
                    The specified file was not found on this website. Please check the URL for
                    mistakes and try again.
                </p>
            </div>
        </Jumbotron>
    )
}

export default NotFound
