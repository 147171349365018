import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeApp } from 'firebase/app';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'index.css';
import reportWebVitals from 'reportWebVitals';
import Header from 'components/layouts/Header';
import Footer from 'components/layouts/Footer';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import * as route from 'constants/routes';
import App from 'pages/App';
import CookiePolicy from 'pages/CookiePolicy';
import TermsAndConditions from 'pages/TermsAndConditions';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import About from 'pages/About';
import VerifyBusiness from 'pages/VerifyBusiness';
import SignUpThankYou from 'pages/SignUpThankYou';
import DeleteData from 'pages/DeleteData';
import NotFound from 'pages/NotFound';
import SubscriptionList from 'pages/Subscriptions/SubscriptionList';
import SubscriptionDurationScreen from 'pages/Subscriptions/SubscriptionDuration';
import WebAppPage from 'pages/SpeedEventApp/EventApp.js';
import PersonalityQuiz from 'pages/PersonalityQuiz';
import { firebaseConfig } from 'constants/config'; // Import your Firebase config

// Initialize Firebase
initializeApp(firebaseConfig);

const router = createBrowserRouter([
    {
        path: route.INDEX,
        element: <App />
    },
    {
        path: route.SUBSCRIPTIONS,
        element: <SubscriptionList />
    },
    {
        path: route.SUBSCRIPTION_DURATION,
        element: <SubscriptionDurationScreen />
    },
    {
        path: route.PERSONALITY_QUIZ,
        element: <PersonalityQuiz title="Personality Quiz" />
    },
    {
        path: route.SIGN_UP_THANK_YOU,
        element: <SignUpThankYou />
    },
    {
        path: route.DELETE_YOUR_DATA,
        element: <DeleteData />
    },
    {
        path: route.ABOUT,
        element: <About />
    },
    {
        path: route.VERIFY_BUSINESS,
        element: <VerifyBusiness />
    },
    {
        path: route.COOKIE_POLICY,
        element: <CookiePolicy />
    },
    {
        path: route.TERMS_AND_CONDITIONS,
        element: <TermsAndConditions />
    },
    {
        path: route.WEB_APP_PAGE,
        element: <WebAppPage />
    },
    {
        path: route.PRIVACY_POLICY,
        element: <PrivacyPolicy />
    },
    {
        path: '*',
        element: <NotFound />
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Header />
        <RouterProvider router={router} />
        <Footer />
    </React.StrictMode>
);

reportWebVitals();
