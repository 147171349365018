import React from 'react';
import './header.css';


interface HeaderNavLinkProps {
    link: string
    text: string
}

const HeaderNavLink: React.FC<HeaderNavLinkProps> = ({ link, text }) => {
    return (
        <li className="nav-item custom-nav-item">
            <a className="nav-link custom-nav-link" href={link}>
                {text}
            </a>
        </li>
    )
}

export default HeaderNavLink