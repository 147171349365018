import Jumbotron from 'components/layouts/Jumbotron'
import React from 'react'

const VerifyBusiness: React.FC = () => {
    return (
        <Jumbotron>
            <div className="container text-center">
                <h1>Your business is verified</h1>
            </div>
        </Jumbotron>
    )
}

export default VerifyBusiness
