import NonJumbotronBanner from 'components/layouts/NonJumbotronBanner';
import React from 'react';
import './About.css';

const About: React.FC = () => {
    return (
        <>
            <NonJumbotronBanner />
            <div className="about-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 mt-1">
                            <h1>Reflect & Connect</h1>
                            <div className="mb-5 section">
                                <h2>About Mirra: Revolutionizing Connections</h2>
                                <p>
                                    At Mirra, we believe that understanding yourself and others is the cornerstone of forming meaningful relationships. This principle guides everything we do, setting us apart in the dating world.
                                </p>
                                <p>
                                    Our platform isn&apos;t just about making connections; it&apos;s about making the right connections. We focus on the depth of character and compatibility beyond the physical, offering our users the opportunity to discover themselves, explore the nuances of others, and enjoy the journey to finding love.
                                </p>
                                <h3>Our Foundations and Vision</h3>
                                <p>
                                    Mirra Dating was born from a unique fusion of expertise and vision shared by our co-founders, Edi and Ryan.
                                </p>
                                <p>
                                    <strong>Edi:</strong> With a rich background in Talent Acquisition, Edi has mastered the art of building high-functioning teams through meticulous recruitment and personality mapping exercises. Observing the parallels between team formation and relationship building, Edi realized that the principles guiding the creation of high-performing teams could be ingeniously applied to the dating world. The goal? To forge high-performing, harmonious relationships based on a deep understanding of personality and preferences.
                                </p>
                                <p>
                                    <strong>Ryan:</strong> Grounded in tech and driven by innovation, Ryan transforms our vision into reality. His expertise ensures that Mirra Dating leverages the latest and future technologies to enhance user experience, facilitating connections that have the potential to grow into meaningful relationships. With Ryan&apos;s guidance, we&apos;re not just part of the dating industry; we&apos;re at the forefront of the technological evolution in dating.
                                </p>
                                <h3>Our Mission</h3>
                                <p>
                                    To revolutionize the dating experience, enabling profound, meaningful connections through self-awareness, mutual understanding, and technological excellence. Mirra Dating is where the future of dating begins—a place for discovering genuine connections, understanding the depth of relationships, and embracing the journey to love.
                                </p>
                                <p>
                                    Join us at Mirra Dating, and be part of a movement that values depth, understanding, and genuine connections. Welcome to the future of dating.
                                </p>
                            </div>
                            <div className="mb-5 section">
                                <h2>Dating</h2>
                                <p>
                                    70 years of life if you&apos;re lucky... If you&apos;re luckier, in half the time you&apos;ll have found someone to spend the rest of your adult life with. Exploring and experiencing this world with that special someone. When you look at it like that it&apos;s not that much time, is it? Wouldn&apos;t you want to make sure and find the special someone quicker and spend it growing together into your best self whilst enjoying this world?
                                </p>
                                <p>
                                    Navigating the world of dating can often feel like an uphill battle. At Mirra, we understand the complexities of finding your perfect partner—your confidant, your steadfast supporter, your life companion. The quest is all the more challenging in a landscape where authenticity often takes a backseat, and the essence of meaningful connections gets lost amidst superficial interactions.
                                </p>
                                <p>
                                    That&apos;s where TMS steps in. Our mission is not just about facilitating dating activities; it&apos;s about pioneering a transformative journey towards genuine connections. We believe that the foundation of a profound relationship begins with you. By fostering self-awareness and promoting a deeper appreciation for the uniqueness of others, we guide you towards discovering not just your match, but a mirror to your soul.
                                </p>
                                <p>
                                    Join us at TMS in our quest for growth, self-discovery, and meaningful relationships. Dive into the Mirra experience—a place where you&apos;ll embark on a journey of self-reflection, understand the core of what you can contribute to a relationship, and learn to cherish the differences that make each connection special.
                                </p>
                                <p>
                                    Embark on this journey with us. Together, let&apos;s redefine dating by building relationships that are rooted in authenticity, understanding, and mutual respect.
                                </p>
                            </div>
                            <div className="mb-5 section">
                                <h2>Events</h2>
                                <h3>Unlock the Magic of Connection at Our Mirra Events</h3>
                                <p>
                                    At Mirra, we aim to help you reflect on what makes you unique and appreciate others, so that you can better connect. So you can spend the short time we have here enjoying this world and growing to our best selves. Reflect, Connect.
                                </p>
                                <p>
                                    &apos;Mirra dating&apos; is our unique take on dating experiences. Apps aren&apos;t doing it right now; real connections and experiences are what&apos;s up. Join the movement of self-awareness.... Starting with our take on &apos;speed dating&apos; and find that special one. Ask the fun, deep (and at times saucy) questions, to find that special someone.... reflect, connect - deeper than a tall guy, from finance, blue eyes, trust fund and all that jazz.
                                </p>
                                <p>
                                    At Mirra, we believe in connections that go beyond the surface, reaching the very essence of who we are. That&apos;s why our Mirra events are curated to bring together incredible individuals on their journey of self-discovery, who not only know themselves deeply but also embrace the beautiful tapestry of human differences. These gatherings are your gateway to finding your perfect complement—the yin to your yang, the Buzz Lightyear to your Woody, the Beauty to your Beast.
                                </p>
                                <p>
                                    But make no mistake, our events are far from your average speed-dating scene. We&apos;ve reimagined the art of meeting people with a focus on meaningful interactions and self-reflection. Through a series of thoughtfully designed activities, we guide you towards making connections that are both genuine and profound. It&apos;s an invitation to explore, to discover, and to find that someone who doesn&apos;t just complete you, but also reflects the very best of you.
                                </p>
                                <h3>Why Join a Mirra Event?</h3>
                                <ul>
                                    <li>Deep Connections: Forge bonds that are rooted in authenticity and mutual appreciation.</li>
                                    <li>Self-Discovery: Gain insights into your own nature, strengths, and how you relate to others.</li>
                                    <li>Celebrate Differences: Learn to cherish the unique qualities of each individual you meet.</li>
                                </ul>
                                <p>
                                    Whether you&apos;re seeking a life partner or meaningful friendships, our Mirra events offer a welcoming space to connect with like-minded souls. This is more than just meeting people; it&apos;s about discovering connections that resonate on a deeper level.
                                </p>
                                <p>
                                    Don&apos;t miss out on the chance to find your complement, to uncover new facets of yourself, and to celebrate the diversity that makes us all uniquely special. Sign up for a Mirra event near you today and embark on a journey where meaningful connections await.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
