import React from 'react'

export interface Question {
    id: string
    questionText: string
    score: number
    scoreEmoji: string
    charge: number
}

interface QuizQuestionProps {
    question: Question
    onScoreChange: (score: number) => void
}

const QuizQuestion: React.FC<QuizQuestionProps> = ({ question, onScoreChange }) => (
    <div className="quiz-question-container">
        <p className="quiz-question-container__question-text">{`"${question.questionText}"`}</p>
        <input
            className="quiz-question-container__input"
            type="range"
            min="-5"
            max="5"
            value={question.score}
            onChange={(e) => onScoreChange(parseInt(e.target.value))}
        />
        <div className="quiz-question-container__view-emoji-container">
            <span>Strongly Disagree</span>
            <span>{question.scoreEmoji}</span>
            <span>Strongly Agree</span>
        </div>
    </div>
)

export default QuizQuestion
