import React, { useState, useRef, ChangeEvent, FormEvent } from 'react'
import Jumbotron from 'components/layouts/Jumbotron'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, addDoc } from 'firebase/firestore'
import { firebaseConfig } from 'constants/config'
import { isValidName, isValidEmail } from 'utils/formValidation'

import 'pages/DeleteData/DeleteData.css'

interface FormData {
    name: string
    email: string
}

interface FieldErrors {
    [fieldName: string]: {
        error: boolean
        errorMessage: string
    }
}

const validateSignUpForm = (formData: FormData): FieldErrors => {
    const errors: FieldErrors = {}

    if (!isValidName(formData.name)) {
        errors.name = {
            error: true,
            errorMessage: 'First Name is required and must be a valid name'
        }
    }

    if (!isValidEmail(formData.email)) {
        errors.email = {
            error: true,
            errorMessage: 'Email is required and must be a valid email address'
        }
    }

    return errors
}

const DeleteData: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: ''
    })
    const [formErrors, setFormErrors] = useState<FieldErrors>({})
    const formRef = useRef<HTMLFormElement | null>(null)
    const [formInProgress, setFormInProgress] = useState<boolean>(false)

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { id, type, value } = e.target

        setFormData((prevData) => ({
            ...prevData,
            [id]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value
        }))
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        setFormInProgress(true)
        console.log('Form data submitted:', formData) // TODO: remove

        if (formInProgress) return

        const validationErrors = validateSignUpForm(formData)

        if (Object.keys(validationErrors).length > 0) {
            console.error('Validation errors:', validationErrors)
            setFormErrors(validationErrors)
            setFormInProgress(false)
            return
        }

        const { name, email } = formData

        const requestData = {
            name: name,
            email: email
        }

        const firebaseApp = initializeApp(firebaseConfig)
        const firestore = getFirestore(firebaseApp)

        // Add the request data to the "delete-requests" collection in Firestore
        addDoc(collection(firestore, 'delete-requests'), requestData)
            .then(() => {
                setFormInProgress(false)
                alert('Your request has been submitted.')

                // Clear the form fields after a successful action
                if (formRef.current) {
                    formRef.current.reset()
                }
            })
            .catch((error) => {
                console.error('Error submitting request: ', error)
                setFormInProgress(false)
            })
    }
    return (
        <Jumbotron>
            <div className="container">
                <h1>Account Deletion Request</h1>
                <div id="delete-account-form">
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <div>
                            {formErrors?.name && (
                                <p className="input-error-text">{formErrors.name.errorMessage}</p>
                            )}
                            <input
                                type="text"
                                id="name"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            {formErrors?.email && (
                                <p className="input-error-text">{formErrors.email.errorMessage}</p>
                            )}
                            <input
                                type="email"
                                id="email"
                                placeholder="Your Email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>

                        <button id="deleteRequestButton" disabled={formInProgress}>
                            Submit Request
                        </button>
                    </form>
                </div>
            </div>
        </Jumbotron>
    )
}

export default DeleteData
