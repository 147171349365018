import React from 'react'
import * as route from 'constants/routes'
import 'components/layouts/Footer/footer.css'

// TODO: Improve Footer spacing between each, so not hard coded but margin ("policy-links a + policy-links a")
const Footer: React.FC = () => {
    return (
        <footer>
            <div className="container">
                <div className="policy-links">
                    <a href={route.COOKIE_POLICY} target="_blank" rel="noopener noreferrer">
                        Cookie Policy
                    </a>{' '}
                    |{' '}
                    <a href={route.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                    </a>{' '}
                    |{' '}
                    <a href={route.TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                    </a>{' '}
                    |{' '}
                    <a href={route.DELETE_YOUR_DATA} target="_blank" rel="noopener noreferrer">
                        Delete your data
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
