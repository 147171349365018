import React from 'react'
import Jumbotron from 'components/layouts/Jumbotron'
import * as route from 'constants/routes'

const SignUpThankYou: React.FC = () => {
    return (
        <Jumbotron>
            <div className="container text-center">
                <div className="col-md-12 text-center mt-5">
                    <h1>Thank You for Registering!</h1>
                    <p>Your registration was successful.</p>
                    <p>
                        <a href={route.INDEX}>Return to the main page</a>
                    </p>
                </div>
            </div>
        </Jumbotron>
    )
}

export default SignUpThankYou
