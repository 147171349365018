import React from 'react'
import './TraitBars.css'

interface TraitBarsProps {
    results: {
        O: number
        C: number
        E: number
        A: number
        N: number
    }
}

const traitNames: { [key: string]: string } = {
    O: 'Openness',
    C: 'Conscientiousness',
    E: 'Extraversion',
    A: 'Agreeableness',
    N: 'Neuroticism'
}

const traitColors: { [key: string]: string } = {
    O: 'orange',
    C: 'teal',
    E: 'green',
    A: 'red',
    N: 'blue'
}

const TraitBars: React.FC<TraitBarsProps> = ({ results }) => {
    return (
        <div className="trait-bars-container">
            {Object.entries(results).map(([trait, value]) => (
                <div key={trait} className="trait-bar-wrapper">
                    <div className="trait-bar-label" style={{ color: traitColors[trait as keyof typeof traitColors] }}>
                        {traitNames[trait as keyof typeof traitNames]}
                    </div>
                    <div className="trait-bar">
                        <div className="trait-bar-background"></div>
                        <div
                            className="trait-bar-inner"
                            style={{
                                width: `${value * 40}%`,
                                backgroundColor: traitColors[trait as keyof typeof traitColors]
                            }}
                        />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TraitBars
