import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'components/Subscription/subscription.css'
import NonJumbotronBanner from 'components/layouts/NonJumbotronBanner'

interface DurationOption {
    duration: string
    price: number
    total: number
}

const SubscriptionDurationScreen: React.FC = () => {
    const [selectedDuration, setSelectedDuration] = useState<string | null>(null)
    const navigate = useNavigate()
    const params = useParams<{ subscriptionType: string }>()

    console.log('Params received:', params)

    const { subscriptionType } = params
    console.log('Subscription type loaded:', subscriptionType)

    let durations: DurationOption[] = []
    console.log('Loaded subscription type:', subscriptionType)

    switch (subscriptionType) {
        case 'subscriber':
            durations = [
                { duration: '1 Month', price: 5.99, total: 5.99 },
                { duration: '3 Months', price: 4.99, total: 14.97 },
                { duration: '6 Months', price: 3.99, total: 23.94 },
                { duration: 'Annual', price: 3.09, total: 37.08 }
            ]
            break
        case 'premium':
            durations = [
                { duration: '1 Month', price: 9.99, total: 9.99 },
                { duration: '3 Months', price: 7.99, total: 23.97 },
                { duration: '6 Months', price: 5.99, total: 35.94 },
                { duration: 'Annual', price: 4.99, total: 59.88 }
            ]
            break
        case 'vip':
            durations = [
                { duration: '1 Month', price: 11.99, total: 11.99 },
                { duration: '3 Months', price: 9.59, total: 28.77 },
                { duration: '6 Months', price: 8.39, total: 50.34 },
                { duration: 'Annual', price: 7.79, total: 93.48 }
            ]
            break
        default:
            console.error('Unhandled subscription type:', subscriptionType)
            durations = [{ duration: 'Free', price: 0, total: 0 }]
            break
    }

    const handleSelect = (duration: string) => {
        setSelectedDuration(duration)
        navigate('/payment')
    }

    return (
        <>
            <NonJumbotronBanner />
            <div className="subscription-duration">
                <h1>Choose Duration</h1>
                {durations.map((item, index) => (
                    <div
                        key={index}
                        className="subscription-duration__container"
                        role="button"
                        tabIndex={0}
                        onClick={() => handleSelect(item.duration)}
                        onKeyDown={(event) => event.key === 'Enter' && handleSelect(item.duration)}>
                        <h2>{item.duration}</h2>
                        <p>
                            {item.price.toFixed(2)}/mo - Total: £{item.total.toFixed(2)}
                        </p>
                        {index !== 0 && (
                            <p>Save {Math.round(100 - (item.price / durations[0].price) * 100)}%</p>
                        )}
                        {selectedDuration === item.duration && <p>Selected</p>}
                    </div>
                ))}
            </div>
        </>
    )
}

export default SubscriptionDurationScreen
