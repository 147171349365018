import React from 'react';
import UserRegistrationForm from 'components/UserRegistrationForm';
import EventList from 'components/Eventlist/Eventlist';
import { MIRRA_LOGO_WHITE } from 'constants/assets';
import './App.css';

const App: React.FC = () => {
    return (
        <div className="app-container">
            <div className="login-content">
                <img
                    src={MIRRA_LOGO_WHITE}
                    alt="Generic placeholder - TODO"
                    className="app-logo"
                />
                <p className="app-text">Sign up for the app below to get first access!</p>

                <div className="app-event-list">
                    <EventList />
                </div>

                <div className="app-registration-form">
                    <UserRegistrationForm />
                </div>
            </div>
        </div>
    );
};

export default App;
