import React, { useEffect, useState } from 'react'
import Jumbotron from 'components/layouts/Jumbotron'
import SubscriptionCard from 'components/Subscription/SubscriptionCard/SubscriptionCard'
import { MIRRA_LOGO_WITH_TEXT } from 'constants/assets'
import 'components/Subscription/subscription.css'
import './SubscriptionList.css';

const SubscriptionList = () => {
  const subscriptionOptions = [
    {
      title: 'Free',
      backgroundColour: 'grey',
      features: [
        'Track-A-Date',
        'Likes: 20 daily',
        'Up to 5% off at Mirra partners',
        '3x Glints',
        'MBTI insights',
        'Shards',
        'Ads Enabled'
      ],
      textColour: 'black',
      isFreeSubscription: true,
      subscriptionType: 'free'
    },
    {
      title: 'Subscriber - from £3.09 per month',
      backgroundColour: 'blue',
      features: [
        'Everything in Free plus More!',
        'Spotify playlists',
        'Up to 10% off at Mirra partners',
        'UNLIMITED Likes',
        '6x Glints',
        'Gift your connections',
        'Priority',
        'No Ads!',
        '15% off Mirra Events',
        '10% off Shards'
      ],
      textColour: 'white',
      isFreeSubscription: false,
      subscriptionType: 'subscriber'
    },
    {
      title: 'Premium - from £4.99 per month',
      backgroundColour: 'amber',
      features: [
        'It gets better than Subscriber!?!',
        'Up to 15% off at Mirra partners',
        'UNLIMITED Likes',
        '10x Glints',
        '5x ReTrace',
        'High priority',
        '7 day profile Boost',
        'See who likes you!',
        '30% off Mirra Events',
        '25% off VIP events',
        '20% off Shards'
      ],
      textColour: 'black',
      isFreeSubscription: false,
      subscriptionType: 'premium'
    },
    {
      title: 'VIP - from £7.79 per month',
      backgroundColour: 'black',
      features: [
        'Everything in the other subscriptions:',
        '20% off at Mirra partners',
        '14x Glints',
        'UNLIMITED ReTrace',
        'VIP priority',
        '2x 7 day profile Boost',
        'Bling - Send a message with your Glint!',
        '50% off Mirra Events',
        'Invites to VIP events - FREE',
        '30% off Shards'
      ],
      textColour: 'white',
      isFreeSubscription: false,
      subscriptionType: 'vip'
    }
  ]

  const [scrolledToLeft, setScrolledToLeft] = useState(true);
  const [scrolledToRight, setScrolledToRight] = useState(false);

  let scrollElement: HTMLElement;

  useEffect(() => {
    scrollElement = document.getElementById('scroll-element')!;
    scrollElement.addEventListener('scroll', updateScroll);
    return () => {
      scrollElement?.removeEventListener('scroll', updateScroll);
    };
  }, []);


  const updateScroll = () => {
    console.log(scrollElement.scrollLeft, scrollElement.scrollWidth, scrollElement.clientWidth);
    setScrolledToLeft(scrollElement.scrollLeft === 0);
    setScrolledToRight(scrollElement.scrollWidth - scrollElement.clientWidth <= scrollElement.scrollLeft + 1);
  };

  let classNames = 'subscription-jumbotron-container';
  if (!scrolledToLeft) {
    classNames += ' can-scroll-left'
  }
  if (!scrolledToRight) {
    classNames += ' can-scroll-right'
  }

  return (
    <div className="h-screen flex items-center justify-center">
      <Jumbotron>
        <div className="container text-center">
          <img
            src={MIRRA_LOGO_WITH_TEXT}
            alt="Generic placeholder - TODO"
            width="150"
            height="150"
            className="img-fluid mb-4"
          />
          <div className={classNames} id="scroll-element">
            {subscriptionOptions.map((option, index) => (
              <SubscriptionCard key={index} {...option} />
            ))}
          </div>
        </div>
      </Jumbotron>
    </div>
  )
}

export default SubscriptionList
