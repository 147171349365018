import React, { useState } from 'react';
import './header.css';
import * as route from 'constants/routes';
import HeaderNavLink from './HeaderNavLink';

const Header: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        console.log("HEADER MENU BUTTON CLICK!!!")
        setIsOpen(!isOpen);
    };

    return (
        <nav id="header-navbar" className="navbar navbar-expand-lg py-4">
            <div className="container">
                <a className="navbar-brand d-flex align-items-center text-white" href={route.INDEX}>
                    <h3 className="font-weight-bolder mb-0">Mirra</h3>
                </a>
                <button className="navbar-toggler" onClick={toggleSidebar}>Menu</button>
                <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbar-nav-header">
                    <ul className="navbar-nav custom-navbar-nav ms-auto">
                        <HeaderNavLink link={route.INDEX} text="Home" />
                        <HeaderNavLink link={route.ABOUT} text="About" />
                        <HeaderNavLink link={route.SUBSCRIPTIONS} text="Subscriptions" />
                        <HeaderNavLink link={route.PERSONALITY_QUIZ} text="Personality Quiz" />
                        <HeaderNavLink link={route.WEB_APP_PAGE} text="Events" />
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Header;
