import React from 'react'
import { useNavigate } from 'react-router-dom'
import 'components/Subscription/subscription.css'

interface SubscriptionCardProps {
    title: string
    backgroundColour: string
    features: string[]
    textColour?: string
    isFreeSubscription: boolean
    subscriptionType: string
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
    title,
    backgroundColour,
    features,
    textColour = 'black',
    isFreeSubscription = false,
    subscriptionType
}) => {
    const navigate = useNavigate()

    return (
        <div
            className={`subscription-card subscription-card-bg-colour-${backgroundColour} subscription-card-text-colour-${textColour}`}>
            <h2>{title}</h2>
            <ul>
                {features.map((feature, index) => (
                    <li key={index}>
                        <span className="feature-icon">✔️</span>
                        <p className="feature-text">{feature}</p>
                    </li>
                ))}
            </ul>
            {!isFreeSubscription && (
                <button
                    className="subscription-button primary-button"
                    onClick={() => navigate(`/subscription-duration/${subscriptionType}`)}>
                    Subscribe Now
                </button>
            )}
        </div>
    )
}

export default SubscriptionCard
