import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './eventlist.css';

type Event = {
    id: string;
    name: { text: string };
    description: { text: string };
    start: { utc: string };
    url: string;
    logo: { url: string };
    venue?: { address?: { localized_address_display: string } }; // Make venue optional
};

type EventbriteResponse = {
    events: Event[];
    pagination: {
        object_count: number;
        page_number: number;
        page_size: number;
        page_count: number;
        has_more_items: boolean;
    };
};

const EventList: React.FC = () => {
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await fetch('https://www.eventbriteapi.com/v3/organizations/2103992477363/events/?status=live&expand=logo,venue', {
                    headers: {
                        'Authorization': `Bearer NODE3PJLELKZVOOI7W7K`
                    }
                });
                const data: EventbriteResponse = await response.json();
                console.log('Fetched data:', data);

                if (response.ok) {
                    if (data.events.length > 0) {
                        setEvents(data.events);
                    } else {
                        setErrorMessage('No events found.');
                    }
                } else {
                    setErrorMessage(`Error fetching events: ${response.statusText}`);
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrorMessage(err.message);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, []);

    if (loading) {
        return <p>Loading events...</p>;
    }

    if (errorMessage) {
        return <p>Error loading events: {errorMessage}</p>;
    }

    if (events.length === 1) {
        const event = events[0];
        return (
            <div className="event-list">
                <div key={event.id} className="event-item">
                    <h3>{event.name.text}</h3>
                    {event.venue && event.venue.address && (
                        <p className="event-location">{event.venue.address.localized_address_display}</p>
                    )}
                    <p>{event.description.text}</p>
                    <p>{new Date(event.start.utc).toLocaleDateString()}</p>
                    {event.logo && <img src={event.logo.url} alt={event.name.text} />}
                    <a href={event.url} target="_blank" rel="noopener noreferrer">View Event</a>
                </div>
            </div>
        );
    }

    return (
        <div className="event-list">
            <Carousel
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={5000}
                centerMode={true}
                centerSlidePercentage={30}
                selectedItem={0}
            >
                {events.map(event => (
                    <div key={event.id} className="event-item">
                        <h3>{event.name.text}</h3>
                        {event.venue && event.venue.address && (
                            <p className="event-location">{event.venue.address.localized_address_display}</p>
                        )}
                        <p>{event.description.text}</p>
                        <p>{new Date(event.start.utc).toLocaleDateString()}</p>
                        {event.logo && <img src={event.logo.url} alt={event.name.text} />}
                        <a href={event.url} target="_blank" rel="noopener noreferrer">View Event</a>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default EventList;