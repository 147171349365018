import React, { useEffect, useState } from 'react'

interface StaticHTMLPageProps {
    URL_PATH: string
}

const StaticHTMLPage: React.FC<StaticHTMLPageProps> = ({ URL_PATH }) => {
    const [staticHTML, setStaticHTML] = useState<string>('')
    // TODO: Add loading state

    useEffect(() => {
        const fetchHTML = async () => {
            try {
                const response = await fetch(URL_PATH)
                const htmlContent = await response.text()
                setStaticHTML(htmlContent)
            } catch (error) {
                // TODO: Add in ERROR HTML elements to display
                console.error('Error fetching HTML:', error)
            }
        }

        fetchHTML()
    }, [URL_PATH])

    return <div dangerouslySetInnerHTML={{ __html: staticHTML }} />
}

export default StaticHTMLPage
