import React, { useState } from 'react';
import TraitInsights from 'components/TraitInsights/TraitInsights';
import 'components/TraitCarousel/TraitCarousel.css';

interface TraitCarouselProps {
  profile: {
    O: number;
    C: number;
    E: number;
    A: number;
    N: number;
  };
}

const traits = ['Openness', 'Conscientiousness', 'Extraversion', 'Agreeableness', 'Neuroticism'] as const;

const TraitCarousel: React.FC<TraitCarouselProps> = ({ profile }) => {
  const scores = [profile.O, profile.C, profile.E, profile.A, profile.N];
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % traits.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + traits.length) % traits.length);
  };

  return (
    <div className="carousel-container">
      <button className="carousel-button" onClick={handlePrev}>
        &lt;
      </button>
      <div className="carousel-item-container">
        {traits.map((trait, index) => {
          const isActive = index === currentIndex;
          const isLeft = (index === (currentIndex - 1 + traits.length) % traits.length);
          const isRight = (index === (currentIndex + 1) % traits.length);

          return (
            <div
              key={trait}
              className={`carousel-item ${isActive ? 'active' : isLeft ? 'inactive-left' : isRight ? 'inactive-right' : ''}`}
            >
              <TraitInsights trait={trait} score={scores[index]} />
            </div>
          );
        })}
      </div>
      <button className="carousel-button" onClick={handleNext}>
        &gt;
      </button>
    </div>
  );
};

export default TraitCarousel;
