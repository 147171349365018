// TODO: maybe replace/move to another location
export const firebaseConfig = {
    apiKey: 'AIzaSyBdke-u286vWvSQ9C7ETs0SClVUPUULMcA',
    authDomain: 'mirr00.firebaseapp.com',
    projectId: 'mirr00',
    storageBucket: 'mirr00.appspot.com',
    messagingSenderId: '928648526048',
    appId: '1:928648526048:web:2bf751d2ad6b7bb9a8b885'
}

// TODO: review if required as a helper function for firebase store
// Initialize Firebase only if it hasn't been initialized yet
// if (!firebase.apps.length) {
//     firebase.initializeApp(firebaseConfig);
// } else {
//     firebase.app(); // if already initialized, use that one
// }

// export const firestore = firebase.firestore();
