export const INDEX = '/'
export const NOT_FOUND = '404'
export const COOKIE_POLICY = 'cookie-policy'
export const PRIVACY_POLICY = 'privacy-policy'
export const TERMS_AND_CONDITIONS = 'terms-and-conditions'

export const SIGN_UP_THANK_YOU = 'thank-you'
export const DELETE_YOUR_DATA = 'delete-your-data'
export const VERIFY_BUSINESS = 'verify-business'
export const ABOUT = 'about'

export const SUBSCRIPTIONS = 'subscriptions'
export const SUBSCRIPTION_DURATION = '/subscription-duration/:subscriptionType'
export const PERSONALITY_QUIZ = 'personality-quiz'

export const WEB_APP_PAGE = 'events'
