import React from 'react';
import Jumbotron from 'components/layouts/Jumbotron';
import './WebAppPage.css';

const WebAppPage = () => {
  return (
    <div style={{ height: '100vh', width: '100%', position: 'relative' }}>
      <Jumbotron>
        <div className="iframe-container">
          <iframe
            src="https://mirra-events.web.app"
            className="web-app-iframe"
            title="Web App"
          ></iframe>
        </div>
      </Jumbotron>
    </div>
  );
};

export default WebAppPage;
