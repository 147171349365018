import React from 'react'
import NonJumbotronBanner from 'components/layouts/NonJumbotronBanner'
import StaticHTMLPage from 'components/layouts/StaticHTMLPage'

const PrivacyPolicy: React.FC = () => {
    return (
        <>
            <NonJumbotronBanner />
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 mt-5">
                        <StaticHTMLPage URL_PATH="./policies/PrivacyPolicy.html" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy
