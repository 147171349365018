import React, { ReactNode } from 'react'
import 'components/layouts/Jumbotron/jumbotron.css'
import { JUMBOTRON_TRIANGLE_4X } from 'constants/assets'

interface JumbotronProps {
    children: ReactNode
    columView?: boolean
}
const Jumbotron: React.FC<JumbotronProps> = ({ children, columView }) => {
    return (
        <div
            className={`jumbotron d-flex align-items-center ${
                columView && 'jumbotron-colum-view'
            }`}>
            {children}

            <div className="rectangle-1"></div>
            <div className="rectangle-2"></div>
            <div className="rectangle-transparent-1"></div>
            <div className="rectangle-transparent-2"></div>
            <div className="circle-1"></div>
            <div className="circle-2"></div>
            <div className="circle-3"></div>
            <div className="triangle triangle-1">
                <img src={JUMBOTRON_TRIANGLE_4X} alt="" />
            </div>
            <div className="triangle triangle-2">
                <img src={JUMBOTRON_TRIANGLE_4X} alt="" />
            </div>
            <div className="triangle triangle-3">
                <img src={JUMBOTRON_TRIANGLE_4X} alt="" />
            </div>
            <div className="triangle triangle-4">
                <img src={JUMBOTRON_TRIANGLE_4X} alt="" />
            </div>
        </div>
    )
}

export default Jumbotron
